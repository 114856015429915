import { Controller } from '@hotwired/stimulus'

function isLeap(year = '') {
  if (year.length === 0) {
    return false
  }
  if (year % 4 === 0 && year % 100 !== 0) {
    return true
  }
  if (year % 400 === 0) {
    return true
  }

  return false
}

function createElementList(count) {
  const firstNode = document.createElement('option')
  firstNode.textContent = '---'
  firstNode.value = ''
  const nodeList = new Array(count).fill('').map((_, idx) => {
    const option = document.createElement('option')
    option.textContent = idx > 8 ? idx + 1 : ['0', idx + 1].join('')
    option.value = idx + 1

    return option
  })

  return [firstNode].concat(nodeList)
}

function getChildCount(month, isLeapYear) {
  const smallMonths = [4, 6, 9, 11]

  if (Number(month) === 2) {
    return isLeapYear ? 29 : 28
  }
  if (smallMonths.includes(Number(month))) {
    return 30
  }

  return 31
}

function appendOptions(element, month, isLeapYear) {
  const optionsCount = getChildCount(month, isLeapYear)
  const nodeList = createElementList(optionsCount)
  element.append(...nodeList)
}

function adjustOptions(element, month, isLeapYear) {
  while (element.firstChild) {
    element.removeChild(element.firstChild)
  }

  appendOptions(element, month, isLeapYear)
}

function disableElement(element) {
  element.setAttribute('disabled', 'disabled')
}

function changeTextColor(element) {
  element.classList.add('text-blueGray-700')
}

function adjustDayOptionsWithMonth(element) {
  const yearField = element.previousElementSibling
  const dayField = element.nextElementSibling
  const selectNumber = dayField.options[dayField.selectedIndex].text
  adjustOptions(dayField, element.value, isLeap(yearField.value))

  if (selectNumber !== '---' && selectNumber !== undefined) {
    const optionIndex = selectNumber.match(/^[0]*(\d+)$/)[1]
    if (dayField.options[optionIndex]) {
      dayField.options[optionIndex].setAttribute('selected', 'selected')
    } else {
      dayField.selectedIndex = 0
    }
  }

  if (selectNumber === '---') {
    dayField.selectedIndex = 0
  }

  ;[element, dayField, yearField].forEach((item) => {
    disableElement(item.options[0])
  })
}

function isValidDate(date) {
  return date instanceof Date && !isNaN(date.getTime())
}

export default class extends Controller {
  static targets = ['selectContainer']

  static values = {
    startDate: String,
    endDate: String,
    compensationStart: String,
    maxDate: String, // ex: compare to currentDate, over 90 days
  }

  connect() {
    const selectNums =
      this.selectContainerTarget.querySelectorAll('select').length
    if (selectNums === 3) {
      this.fullDateFlow()
    } else if (selectNums === 2) {
      this.yearAndMonthFlow()
    }
  }

  fullDateFlow() {
    const [yearField, monthField, dayField] =
      this.selectContainerTarget.children
    const yearAction = yearField.getAttribute('data-action') || ''
    const monthAction = monthField.getAttribute('data-action') || ''
    const dayAction = dayField?.getAttribute('data-action') || ''
    const birthAction =
      this.selectContainerTarget.getAttribute('data-action') || ''

    if (monthField) {
      yearField.setAttribute(
        'data-action',
        [
          yearAction,
          'change->select-date#modifyDayOptionsByMonth blur->consultant-form-valid#validateDateSelect',
        ].join(' ')
      )
      monthField.setAttribute(
        'data-action',
        [
          monthAction,
          'change->select-date#modifyDayOptionsByMonth blur->consultant-form-valid#validateDateSelect',
        ].join(' ')
      )
      dayField.setAttribute(
        'data-action',
        [
          dayAction,
          'change->select-date#dayHandler blur->consultant-form-valid#validateDateSelect',
        ].join(' ')
      )

      if (this.startDateValue && this.endDateValue) {
        yearField.setAttribute(
          'data-action',
          [
            yearField.getAttribute('data-action'),
            'change->select-date#adjustDisabledOptions',
          ].join(' ')
        )
        monthField.setAttribute(
          'data-action',
          [
            monthField.getAttribute('data-action'),
            'change->select-date#adjustDisabledOptions',
          ].join(' ')
        )
      }

      Array.from(this.selectContainerTarget.children).forEach((element) => {
        element.setAttribute('data-valid', '["notBlank"]')
      })

      adjustDayOptionsWithMonth(monthField)
    }

    if (yearField) {
      yearField.addEventListener('focus', (e) => {
        const today = new Date()

        if (e.target.value === '') {
          yearField.value = today.getFullYear() - 40
          changeTextColor(e.target)
        }
      })
    }

    if (this.startDateValue && this.endDateValue) {
      this.adjustDisabledOptions()
    }
    // here would be affected by consultant_form_valid_controller, need to use blur not change action
    if (this.compensationStartValue) {
      this.selectContainerTarget
        .querySelectorAll('select')
        .forEach((select) => {
          select.setAttribute(
            'data-action',
            [birthAction, 'blur->select-date#validateDateOfBirth'].join(' ')
          )
        })
    }
  }

  yearAndMonthFlow() {
    this.selectContainerTarget.setAttribute(
      'data-action',
      'change->select-date#validateBuildingCompletionDate'
    )
  }

  dayHandler(e) {
    const dayField = this.selectContainerTarget.children[2]
    disableElement(dayField.options[0])
    changeTextColor(e.target)
  }

  modifyDayOptionsByMonth(e) {
    const monthField = this.selectContainerTarget.children[1]

    if (monthField) {
      adjustDayOptionsWithMonth(monthField)
      changeTextColor(e.target)
    }
  }

  adjustDisabledOptions(event) {
    const [selectedYear, selectedMonth, selectedDay] = Array.from(
      this.selectContainerTarget.children
    ).map((element) => Number(element.value))
    const [startYear, startMonth, startDay] = this.startDateValue
      .split('-')
      .map((item) => Number(item))
    const [endYear, endMonth, endDay] = this.endDateValue
      .split('-')
      .map((item) => Number(item))
    const [yearField, monthField, dayField] = [
      this.selectContainerTarget.children[0],
      this.selectContainerTarget.children[1],
      this.selectContainerTarget.children[2],
    ]

    if (event && yearField.isSameNode(event.target)) {
      if (!monthField.value) {
        monthField.classList.add('pulldown-date--error')
        monthField.style.backgroundColor = '#FFFBEB'
      }
      if (!dayField.value) {
        dayField.classList.add('pulldown-date--error')
        dayField.style.backgroundColor = '#FFFBEB'
      }
    }

    if (selectedYear === startYear && selectedMonth < startMonth) {
      monthField.selectedIndex = 0
    }
    if (selectedYear === endYear && selectedMonth > endMonth) {
      monthField.selectedIndex = 0
    }
    if (
      selectedYear === startYear &&
      selectedMonth === startMonth &&
      selectedDay < startDay
    ) {
      dayField.selectedIndex = 0
    }
    if (
      selectedYear === endYear &&
      selectedMonth === endMonth &&
      selectedDay > endDay
    ) {
      dayField.selectedIndex = 0
    }

    for (let i = 1; i < monthField.options.length; i += 1) {
      if (startYear === endYear && (i < startMonth || i > endMonth)) {
        monthField.options[i].setAttribute('disabled', true)
      } else if (selectedYear === startYear && i < startMonth) {
        monthField.options[i].setAttribute('disabled', true)
      } else if (selectedYear === endYear && i > endMonth) {
        monthField.options[i].setAttribute('disabled', true)
      } else {
        monthField.options[i].removeAttribute('disabled')
      }
    }

    for (let i = 1; i < dayField.options.length; i += 1) {
      if (
        selectedYear === startYear &&
        selectedMonth === startMonth &&
        i < startDay
      ) {
        dayField.options[i].setAttribute('disabled', true)
      }
      if (
        selectedYear === endYear &&
        selectedMonth === endMonth &&
        i > endDay
      ) {
        dayField.options[i].setAttribute('disabled', true)
      }
    }
  }

  validateDateOfBirth() {
    const [birthDateYear, birthDateMonth, birthDateDay] = [
      ...this.element.querySelectorAll('select'),
    ].map((dom) => dom.value)
    const birthDateValue = new Date(
      `${birthDateYear}-${birthDateMonth}-${birthDateDay}`
    )
    const compensationStartDateValue = new Date(this.compensationStartValue)
    compensationStartDateValue.setFullYear(
      compensationStartDateValue.getFullYear() - 18
    )

    const eighteenYearsAgo = new Date()
    eighteenYearsAgo.setFullYear(eighteenYearsAgo.getFullYear() - 18)

    if (!birthDateValue || !compensationStartDateValue) {
      return
    }
    this.checkError('date_of_birth')
    if (
      birthDateValue > compensationStartDateValue ||
      birthDateValue > eighteenYearsAgo
    ) {
      this.createErrorMsg('ご契約は18歳以上の方が対象です', 'date_of_birth')
    }
  }

  validateBuildingCompletionDate() {
    const [buildingCompletionDateYear, buildingCompletionDateMonth] = [
      ...this.element.querySelectorAll('select'),
    ].map((dom) => dom.value)
    const buildingCompletionDateValue = new Date(
      `${buildingCompletionDateYear}/${buildingCompletionDateMonth}/01`
    )
    this.checkError('building_completion_date')
    const isInvalidDate = !isValidDate(buildingCompletionDateValue)
    const isOverMaxDate =
      new Date(buildingCompletionDateValue) > new Date(this.maxDateValue)
    if (isInvalidDate || isOverMaxDate) {
      this.createErrorMsg(
        '本日から90日以内の日付を選択してください',
        'building_completion_date'
      )
    }
  }

  checkError(name) {
    const targetElement = document.querySelector(`[name="${name}"]`)
    if (targetElement) {
      targetElement
        .querySelectorAll('.input__alert')
        .forEach((element) => element.remove())
    }

    if (this.element) {
      this.element
        .querySelectorAll('select.pulldown-date--error')
        .forEach((select) => select.classList.remove('pulldown-date--error'))
    }
  }

  createErrorMsg(msg, name) {
    this.element.querySelectorAll('select').forEach((select) => {
      // !/^[a-zA-Z0-9]+$/.test(e.target.value) => if the answer is blank or contains only symbols, it will return true
      if (!/^[a-zA-Z0-9]+$/.test(select.value)) {
        select.classList.add('pulldown-date--error')
      }
    })
    const errorMsgDiv = document.createElement('div')
    errorMsgDiv.classList.add('input__alert')

    const errorMsg = document.createElement('p')
    errorMsg.classList.add('input__alert-msg')
    errorMsg.textContent = msg

    errorMsgDiv.appendChild(errorMsg)
    const targetElement = document.querySelector(`[name="${name}"]`)
    !!targetElement && targetElement.appendChild(errorMsgDiv)
  }
}
